import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import './Navbar.scss';

import Dropdown from 'react-bootstrap/Dropdown';

export const NavLink = styled(Link)`
    transition: all 0.2s ease-in-out;
    &.active {
        color: #26b8eb;
        
        &:hover {
            color: #ef3e40;
        }
    }


`;

const Navbar = () => {


    const [click, setClick] = useState(false);
    const [setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    //mobile btn
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    window.addEventListener('resize', showButton);

    return (
        <>
        
            <nav className="navbar">

                {/* <NavMenu > */}

                <div className="navbar-container landing-nav">
                    
                    <a href='/' className="navbar-logo"  target='_parent'>
                        <img src="/QC LOGO-04.png" alt="QC Holiday - Malaysia No.1 Car Refinancing Service"/>
                    </a>

                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    

                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <NavLink to='/#core-business' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Core Business
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#about-us' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                About Us
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#why-choose-us' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Why Choose Us
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#customer-reviews' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Customer Reviews
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#contact-us' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Contact Us
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#range-of-cars' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Explore Range of Car
                            </NavLink>
                        </li>

                    </ul>
                    <Dropdown className="nav-dropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                            English
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#">English</Dropdown.Item>
                            <Dropdown.Item href="/zh/">简体中文</Dropdown.Item>
                            <Dropdown.Item href="/bm/">Bahasa Melayu</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div> {/*  close navbar-container */}
                {/* </NavMenu> */}
                
                <div className="navbar-container mobile-nav" >
                    
                    <a href='/' className="navbar-logo"  target='_parent'>
                        <img src="/QC LOGO-04.png" alt="QC Holiday - Malaysia No.1 Car Refinancing Service"/>
                    </a>


                    <Dropdown className="nav-dropdown" onClick={closeMobileMenu}>
                        <Dropdown.Toggle id="dropdown-basic">
                            English
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#">English</Dropdown.Item>
                            <Dropdown.Item href="/zh/">简体中文</Dropdown.Item>
                            <Dropdown.Item href="/bm/">Bahasa Melayu</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    

                    <ul className={click ? 'nav-menu active' : 'nav-menu'} onClick={closeMobileMenu} >
                        
                        <li className='nav-item'>
                            <NavLink to='/#core-business' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Core Business
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#about-us' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                About Us
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#why-choose-us' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Why Choose Us
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#customer-reviews' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Customer Reviews
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#contact-us' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Contact Us
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#range-of-cars' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Explore Range of Car
                            </NavLink>
                        </li>
                    </ul>
                </div>

            </nav>
            
        </>

    )

};

export default Navbar;

import React from 'react';
import '../../App.css';
import Landing from '../Landing';
import Navbar from '../navigation/Navbar';
import Footer from '../navigation/Footer';

function Home() {

    return (
        <> 
            <Navbar />

            <Landing />

            <Footer />
        </>
    ); 
}

export default Home;
import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import '../navigation/Navbar.scss';

import Dropdown from 'react-bootstrap/Dropdown';

export const NavLink = styled(Link)`
    transition: all 0.2s ease-in-out;
    &.active {
        color: #26b8eb;
        
        &:hover {
            color: #ef3e40;
        }
    }


`;

const Navbar = () => {


    const [click, setClick] = useState(false);
    const [setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    //mobile btn
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    window.addEventListener('resize', showButton);

    return (
        <>
        
            <nav className="navbar">

                {/* <NavMenu > */}

                <div className="navbar-container landing-nav">
                    
                    <a href='/bm/' className="navbar-logo"  target='_parent'>
                        <img src="/QC LOGO-04.png" alt="QC Holiday - Malaysia No.1 Car Refinancing Service"/>
                    </a>

                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    

                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <NavLink to='/bm/#perniagaan-teras' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Perniagaan Teras
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/bm/#tentang-kita' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Tentang Kita 
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/bm/#kenapa-pilih-kami' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Kenapa Pilih Kami
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/bm/#maklumbalas-pelanggan' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Maklumbalas Pelanggan
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/bm/#hubungi-kami' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Hubungi Kami
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/bm/#jenis-kereta' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Jenis Kereta
                            </NavLink>
                        </li>

                    </ul>
                    <Dropdown className="nav-dropdown">
                        <Dropdown.Toggle  id="dropdown-basic">
                        Bahasa Melayu
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="/">English</Dropdown.Item>
                            <Dropdown.Item href="/zh/">简体中文</Dropdown.Item>
                            <Dropdown.Item href="#">Bahasa Melayu</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div> {/*  close navbar-container */}
                {/* </NavMenu> */}
                
                <div className="navbar-container mobile-nav" >
                    
                    <a href='/bm/' className="navbar-logo"  target='_parent'>
                        <img src="/QC LOGO-04.png" alt="QC Holiday - Malaysia No.1 Car Refinancing Service"/>
                    </a>

                    <Dropdown className="nav-dropdown" onClick={closeMobileMenu}>
                        <Dropdown.Toggle  id="dropdown-basic">
                            Bahasa Melayu
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="/">English</Dropdown.Item>
                            <Dropdown.Item href="/zh/">简体中文</Dropdown.Item>
                            <Dropdown.Item href="#">Bahasa Melayu</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>

                    <ul className={click ? 'nav-menu active' : 'nav-menu'} onClick={closeMobileMenu} >
                        
                        <li className='nav-item'>
                            <NavLink to='/bm/#perniagaan-teras' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Perniagaan Teras
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/bm/#tentang-kita' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Tentang Kita
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/bm/#kenapa-pilih-kami' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Kenapa Pilih Kami
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/bm/#maklumbalas-pelanggan' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Maklumbalas Pelanggan
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/bm/#hubungi-kami' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Hubungi Kami
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/bm/#jenis-kereta' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                            Jenis Kereta
                            </NavLink>
                        </li>
                    </ul>
                </div>

            </nav>
            
        </>

    )

};

export default Navbar;

import React, { useRef, useState } from 'react';
import '../Landing.scss';
import Navbar from './NavbarZH';
import Footer from './FooterZH';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import emailjs from '@emailjs/browser';
import Modal from 'react-bootstrap/Modal';

function ReviewItem (props) {
    return (
        <>
            <div className="ld-review-item-header">
                <figure>
                    <img src={props.review_imgsrc} alt={props.review_imgalttext} />
                </figure>
                <div>
                    <div className="ld-review-name">{props.review_name}</div>
                    <div className="ld-review-location">{props.review_location}</div>
                    <div className="ld-review-rating">
                        {props.review_rating}
                        {/* <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span> */}
                    </div>
                </div>
            </div>
            <div className="ld-review-item-linediv"></div>
            <div className="ld-review-item-desc">
                <div>{props.review_title}</div>
                <p>{props.review_desc}</p>
            </div>
        </>
    )
}

function LandingZH() {

    const corebusiness_1 = require('../../images/BG_06.png');
    const corebusiness_2 = require('../../images/BG_03.png');
    const corebusiness_3 = require('../../images/BG_02.png');
    const corebusiness_4 = require('../../images/BG_08.png');
    const corebusiness_5 = require('../../images/BG_11.png');
    const corebusiness_6 = require('../../images/BG_09.png');
    const corebusiness_7 = require('../../images/BG_10.png');

    const aboutus_0 = require('../../images/BG_19_01.png');
    // const aboutus_1 = require('../../images/BG_19.png');

    const whychooseus_1 = require('../../images/BG_35.png');

    const sales_1 = require('../../images/sales_01.png');
    const sales_2 = require('../../images/sales_02.png');

    const companyphoto_1 = require('../../images/BG_36_01.jpg');

    const review_1 = require('../../images/review_01.png');
    const review_2 = require('../../images/review_02.png');
    const review_3 = require('../../images/review_03.png');

    const contactus_1 = require('../../images/BG_28_01.png');
    const contactus_2 = require('../../images/email_01.png');


    const reviews = {
        className: "center",
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        swipeToSlide: true,
        afterChange: function(index) {
            console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            );
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    };

    const reviewrating5 = [
        <>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        </>
    ]

    // modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
  
        //   emailjs.sendForm('SERVICE_ID', 'TEMPLATE_ID', form.current, 'USER_ID')
        emailjs.sendForm('service_95ty38a', 'template_ycj7qbb', form.current, 'FGCPYj26CkHeoYuvz')
            .then((result) => {
                console.log(result.text);
                // alert("表格发送成功！ 我们会尽快与您联系!");
                setShow(true);
            }, (error) => {
                console.log(error.text);
            });
    };
    
    // salesperson modal
    const [showsales1, setSalesShow1] = useState(false);
    const handleSalesClose1 = () => setSalesShow1(false);
    const handleSalesShow1 = () => setSalesShow1(true);
    
    const [showsales2, setSalesShow2] = useState(false);
    const handleSalesClose2 = () => setSalesShow2(false);
    const handleSalesShow2 = () => setSalesShow2(true);


    return (

        <>

            <Navbar />

            <div className="wrap-landing">

                <div className="wrap-hero-ld">
                    <div className="hero-ld-content">
                        <div className="hero-text-1">与我们一起汽车融资、出租、购买或出售您的汽车！</div>

                        <h1>全马第一您值得信赖的一站式汽车经销平台</h1>
                        <p>今天用您的车赚取额外现金流!</p>

                        <a href="#core-business" rel="noreferrer nofollow" className="hero-learnmore-btn">
                            <button type="button">查看更多</button>
                        </a>
                    </div>
                </div>


                <div className="land-container">

                    <section className="land-content-0">
                        {/* <div className="ld-cont-header">
                            <h2>Our Story</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div> */}
                        <div>
                            <iframe src="https://player.vimeo.com/video/759163684?h=c6d4d2fd73&byline=0&portrait=0" 
                            // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                            allowfullscreen></iframe>
                        </div>
                    </section>

                    <section className="land-content-1" id="core-business">
                        <div className="ld-cont-header">
                            <h2>主要业务</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-1-container-1">
                            <div className="ld-cont-1-item">
                                <img src={corebusiness_1} alt="Mortgage Loan Refinance - QC Holiday" />
                                <h3>抵押汽车贷款融资</h3>
                                <div className="ld-cont-1-item-linediv" />
                                <p>与我们一起减免您的财务！我们将为身为大马人民的您提供最低的利率的汽车抵押贷款及再融资服务。</p>
                            </div>
                            <div className="ld-cont-1-item">
                                <img src={corebusiness_2} alt="Car Rental - QC Holiday" />
                                <h3>租车</h3>
                                <div className="ld-cont-1-item-linediv" />
                                <p>探索广泛的车辆款式选择。以最优惠价格获得长期租车服务。</p>
                            </div>
                            <div className="ld-cont-1-item">
                                <img src={corebusiness_3} alt="Buy or Sell Your Car - QC Holiday" />
                                <h3>购买或出售您的汽车</h3>
                                <div className="ld-cont-1-item-linediv" />
                                <p>我们致力于提供市场上最优惠的价格！我们将免费为您提供咨询以及销售汽车服务。</p>
                            </div>
                        </div>

                        <div className="ld-cont-1-container-2">
                            <div className="ld-cont-1-item-2 item-2-left item-2-mob-left">
                                <img src={corebusiness_4} alt="18+ Cities in Malaysia - QC Holiday" />
                                <p>18+ 各城市 <br/>
                                    <span>马来西亚</span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                            <div className="ld-cont-1-item-2 item-2-left item-2-mob-right">
                                <img src={corebusiness_5} alt="1.5 Million Total Satisfied Customers - QC Holiday" />
                                <p>150 万 <br/>
                                    <span>个满意的客户 </span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                            <div className="ld-cont-1-item-2 item-2-right item-2-mob-left">
                                <img src={corebusiness_6} alt="4,300+ Trusted Used Car Dealer - QC Holiday" />
                                <p>4,300 多家 <br/>
                                    <span>值得信赖的二手车经销商 </span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                            <div className="ld-cont-1-item-2 item-2-right item-2-mob-right">
                                <img src={corebusiness_7} alt="80,000 Transactions On An Annualized Basis - QC Holiday" />
                                <p>每年 80,000  <br/>
                                    <span>笔交易</span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                        </div>
                    </section>

                    
                    {/* <section className="land-content-2" id="about-us">
                        <div className="ld-cont-header">
                            <h2>Video</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                    </section> */}

                    
                    <section className="land-content-3" id="about-us">
                        <img src={aboutus_0} alt="Mortgage Loan Refinance - QC Holiday" className="ld-cont-3-img-mobile"/>
                        <div className="ld-cont-header">
                            <h2>关于我们</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>
                        
                        <div className="ld-cont-3-container-1">
                            <div className="ld-cont-3-cont-1-item">
                                <div className="ld-cont-3-cont-1-item-1">
                                    <img src={aboutus_0} alt="Mortgage Loan Refinance - QC Holiday" />
                                </div>

                                <div className="ld-cont-3-cont-1-item-2">
                                    <p>我们了解许多人在申请银行汽车贷款时面临的挑战，尤其是在MCO阶段之后。因此，QC HOLIDAY PREMIUM RENTAL SDN. BHD. 致力于创造性地提供有关汽车租赁服务，买卖二手车以及汽车抵押金融服务的全方位一站式服务，以减轻每个人的债务偿还计划。</p>
                                    <p>我们理解到本公司对满足需求的微小贡献将对顾客的经济状况产生巨大影响；因此，我们通过为汽车再融资服务，支持、并向每一位客户提供高品质服务。</p>
                                    <p>只要您有车证，无论您是否被列入黑名单或破产，请联系我们进行汽车抵押贷款再融资服务。我们只存放您的车卡和备用钥匙，您仍然可以继续使用您的汽车。</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="ld-cont-3-container-2">
                            <div className="ld-cont-3-cont-2-item">
                                <div className="ld-cont-3-cont-2-item-1">
                                    <h3>愿景</h3>
                                    <div className="ld-cont-3-cont-2-item-1-linediv"></div>
                                    <p>我们的愿景是提供满足当今市场需求以及对未来梦想的最佳汽车和状况经济状况提供最合适的您解决方案</p>
                                </div>

                                <div className="ld-cont-3-cont-2-item-1">
                                    <h3>使命</h3>
                                    <div className="ld-cont-3-cont-2-item-1-linediv"></div>
                                    <p>我们的使命是帮助人们创建全透明的汽车检验到车权转让以及汽车融资选择的一站式解决方案</p>
                                </div>
                            </div>
                        </div>

                    </section>

                    
                    <section className="land-content-4" id="why-choose-us">
                        <div className="ld-cont-header">
                            <h2>我们的优势</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-4-container-1">
                            <figure>
                                <img src={whychooseus_1} alt="Why Choose Us - QC Holiday" />
                            </figure>
                        </div>

                        <div className="ld-cont-4-container-2">
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>流程简易</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>从免费检验汽车到为您处理所有书面工作的程序将简易化</p>
                            </div>
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>安全省心</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>没有隐藏费用，没有额外加价，并提供广泛的融资类型选择</p>
                            </div>
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>专业的咨询服务</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>通过检查提供最好的客户咨询与服务，协助您找出您汽车的最高价值</p>
                            </div>
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>广泛的车辆报价</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>享受各种车辆类型（包括汽车、摩托车、卡车或任何车辆类型）的超值回扣、以高价旧换新的车辆</p>
                            </div>
                        </div>

                        <div className="ld-cont-4-container-3">
                            <div className="ld-cont-4-cont-3-item-2">
                                <figure onClick={handleSalesShow1}>
                                    <img src={sales_1} alt="David Heng - QC Holiday" />
                                </figure>
                            </div>

                            <div className="ld-cont-4-cont-3-item-2">
                                <figure onClick={handleSalesShow2}>
                                    <img src={sales_2} alt="Caslyn Koh - QC Holiday" />
                                </figure>
                            </div>

                            {/* <div className="ld-cont-4-cont-3-item-1">
                                <iframe src="https://player.vimeo.com/video/749881614?h=2e3f5db88a&title=0&byline=0&portrait=0" 
                                // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                                allowfullscreen></iframe>
                            </div>
                            
                            <div className="ld-cont-4-cont-3-item-1">
                                <iframe src="https://player.vimeo.com/video/754143660?h=c84a134a84&title=0&byline=0&portrait=0" 
                                // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                                allowfullscreen></iframe>
                            </div> */}
                        </div>
                    </section>
                    
                    
                    <section className="land-content-5" id="why-choose-us">
                        <div className="ld-cont-header">
                            <h2>我们的公司</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-5-container-1">
                            <figure>
                                <img src={companyphoto_1} alt="Our Company - QC Holiday" />
                            </figure>
                        </div>

                    </section>
                    
                    
                    <section className="land-content-6" id="customer-reviews">
                        <div className="ld-cont-header">
                            <h2>顾客评论</h2>
                            <div className="ld-cont-h-linediv"></div>                        
                        </div>
                        <p>我们的客户类型范围，从学生到知名商人。我们重视每个人！</p>

                        <div className="ld-cont-6-container-1">
                            <Slider {...reviews}>
                                <div className="ld-cont-6-item-1">
                                    <ReviewItem
                                        review_imgsrc={review_1}
                                        review_imgalttext="Customer Review - QC Holiday Johor"
                                        review_name="Jason Kok Chin Meng"
                                        review_location="Johor, Malaysia"
                                        review_rating={reviewrating5}
                                        review_title="以高于市场价值 10% 的价格出售汽车"
                                        review_desc="真棒汽车销售平台，过程简单流畅简单。他们的顾问和工作人员非常友善。总体而言，从汽车检查到交付的服务快速高效。"
                                    />
                                </div>

                                <div className="ld-cont-6-item-1">
                                    <ReviewItem
                                        review_imgsrc={review_2}
                                        review_imgalttext="Customer Review - QC Holiday Pahang"
                                        review_name="Nur Fazura"
                                        review_location="Pahang, Malaysia"
                                        review_rating={reviewrating5}
                                        review_title="汽车再融资批准 RM130K"
                                        review_desc="与他们联系是最佳的选择，并且肯定会推荐人们在这里租车。如果您处于紧急情况或艰难时期要寻找汽车再融资贷款服务，这公司是一个很好的选择……强烈推荐！"
                                    />
                                </div>

                                <div className="ld-cont-6-item-1">
                                    <ReviewItem
                                        review_imgsrc={review_3}
                                        review_imgalttext="Customer Review - QC Holiday Melaka"
                                        review_name="Joey Chang"
                                        review_location="Melaka, Malaysia"
                                        review_rating={reviewrating5}
                                        review_title="以高价出售汽车并购买新汽车"
                                        review_desc="作为一个女人，我不熟悉买车和检验汽车，但是这家公司使它透明化且值得信赖。感谢 QC HOLIDAY RENTAL 以优惠的价格为我出售汽车，并让我有机会获得更高的出售价格以选择我的新车辆。"
                                    />
                                </div>
                            </Slider>
                        </div>
                    </section>
                    
                    
                    <section className="land-content-7" id="contact-us">
                        <div className="ld-cont-header">
                            <h2>立即联系我们</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>
                        <p>准备好换一辆更好的车了吗？</p>

                        <div className="ld-cont-7-container-1">
                            <div className="ld-cont-7-item-1">
                                <form className="cont-us-form-content" ref={form} onSubmit={sendEmail} >
                                    <div className="cont-us-form-row-1">
                                        <div>
                                            <input type="text" id="fname" name="fname" placeholder="名字 / First Name" required/>
                                        </div>
                                        
                                        <div>
                                            <input type="text" id="lname" name="lname" placeholder="姓名 / Last Name" required/>
                                        </div>
                                    </div>

                                    <div className="cont-us-form-row-1">
                                        <div>
                                            <input type="email" id="email" name="email" placeholder="电子邮件 / Email Address" required/>
                                        </div>

                                        <div>
                                            <input type="tel" id="phonenumber" name="phonenumber" placeholder="联系电话 / Phone Number" />
                                        </div>
                                    </div>

                                    <div className="cont-us-form-row-2">
                                        <textarea type="text" id="message" name="message" placeholder="留言 / Message" required/>
                                    </div>
                                    
                                    <div className="cont-us-form-row-3">
                                        <button className="cont-us-form-submit" type="submit">Submit</button>
                                    </div>
                                </form> 
                            </div>
                            
                            <div className="ld-cont-7-item-2">
                                <figure>
                                    <img src={contactus_1} alt="Contact Us - QC Holiday" />
                                </figure>
                            </div>
                        </div>
                    </section>
                    
                    
                    <section className="land-content-8" id="range-of-cars">
                        <div className="ld-cont-header">
                            <h2>探索我们的汽车系列</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-8-container">
                            <div className="ld-cont-8-item hatchback">
                                <h3>Hatchback</h3>
                            </div>
                            <div className="ld-cont-8-item suv">
                                <h3>SUV</h3>
                            </div>
                            <div className="ld-cont-8-item mpv">
                                <h3>MPV</h3>
                            </div>
                            <div className="ld-cont-8-item sedan">
                                <h3>Sedan</h3>
                            </div>
                            <div className="ld-cont-8-item lorry">
                                <h3>Lorry</h3>
                            </div>
                            <div className="ld-cont-8-item others">
                                <h3>Others</h3>
                            </div>
                            
                        </div>
                    </section>
                </div>
            </div>
        
            <Modal show={show} onHide={handleClose} className="emailJS-modal">
                <Modal.Header closeButton> </Modal.Header>

                <Modal.Body>
                    <img src={contactus_2} alt="Contact Us - QC Holiday"/>

                    <h3>谢谢</h3>
                    <p>您很快就会收到我们的来信!</p>
                </Modal.Body>

                <Modal.Footer> </Modal.Footer>
            </Modal>

            {/* sales modal */}
            <Modal show={showsales1} onHide={handleSalesClose1} className="salesvid-modal" centered>
                <Modal.Body>
                    <iframe src="https://player.vimeo.com/video/749881614?h=2e3f5db88a&title=0&byline=0&portrait=0" 
                        // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                        allowfullscreen></iframe>
                </Modal.Body>
            </Modal>
            <Modal show={showsales2} onHide={handleSalesClose2} className="salesvid-modal" centered>
                <Modal.Body>
                    <iframe src="https://player.vimeo.com/video/754143660?h=c84a134a84&title=0&byline=0&portrait=0" 
                        // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                        allowfullscreen></iframe>
                </Modal.Body>
            </Modal>

            <Footer />
        </>

    );

}

export default LandingZH;
import React, { useRef, useState }  from 'react';
import './Landing.scss';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import emailjs from '@emailjs/browser';
import Modal from 'react-bootstrap/Modal';


function ReviewItem (props) {
    return (
        <>
            <div className="ld-review-item-header">
                <figure>
                    <img src={props.review_imgsrc} alt={props.review_imgalttext} />
                </figure>
                <div>
                    <div className="ld-review-name">{props.review_name}</div>
                    <div className="ld-review-location">{props.review_location}</div>
                    <div className="ld-review-rating">
                        {props.review_rating}
                        {/* <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span> */}
                    </div>
                </div>
            </div>
            <div className="ld-review-item-linediv"></div>
            <div className="ld-review-item-desc">
                <div>{props.review_title}</div>
                <p>{props.review_desc}</p>
            </div>
        </>
    )
}

function Landing() {

    const corebusiness_1 = require('../images/BG_06.png');
    const corebusiness_2 = require('../images/BG_03.png');
    const corebusiness_3 = require('../images/BG_02.png');
    const corebusiness_4 = require('../images/BG_08.png');
    const corebusiness_5 = require('../images/BG_11.png');
    const corebusiness_6 = require('../images/BG_09.png');
    const corebusiness_7 = require('../images/BG_10.png');

    const aboutus_0 = require('../images/BG_19_01.png');
    // const aboutus_1 = require('../images/BG_19.png');

    const whychooseus_1 = require('../images/BG_35.png');

    const companyphoto_1 = require('../images/BG_36_01.jpg');

    const sales_1 = require('../images/sales_01.png');
    const sales_2 = require('../images/sales_02.png');

    const review_1 = require('../images/review_01.png');
    const review_2 = require('../images/review_02.png');
    const review_3 = require('../images/review_03.png');

    const contactus_1 = require('../images/BG_28_01.png');
    const contactus_2 = require('../images/email_01.png');


    const reviews = {
        className: "center",
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        swipeToSlide: true,
        afterChange: function(index) {
            console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            );
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    };

    const reviewrating5 = [
        <>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        </>
    ]

    // email modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
  
        //   emailjs.sendForm('SERVICE_ID', 'TEMPLATE_ID', form.current, 'USER_ID')
        emailjs.sendForm('service_95ty38a', 'template_ycj7qbb', form.current, 'FGCPYj26CkHeoYuvz')
            .then((result) => {
                console.log(result.text);
                // alert("Form sent successfully! We will contact you as soon as possible!");
                setShow(true);
            }, (error) => {
                console.log(error.text);
            });
    };

    // salesperson modal
    const [showsales1, setSalesShow1] = useState(false);
    const handleSalesClose1 = () => setSalesShow1(false);
    const handleSalesShow1 = () => setSalesShow1(true);
    
    const [showsales2, setSalesShow2] = useState(false);
    const handleSalesClose2 = () => setSalesShow2(false);
    const handleSalesShow2 = () => setSalesShow2(true);
    
    return (

        <>
            <div className="wrap-landing">

                <div className="wrap-hero-ld">
                    <div className="hero-ld-content">
                        <div className="hero-text-1">Refinance, Rent, Buy, or Sell your car with us!</div>

                        <h1>Malaysia No.1 Car Refinancing Service</h1>
                        <p>Your reliable one-stop car provider!</p>

                        <a href="#core-business" rel="noreferrer nofollow" className="hero-learnmore-btn">
                            <button type="button">Learn More</button>
                        </a>
                    </div>
                </div>


                <div className="land-container">

                    <section className="land-content-0">
                        {/* <div className="ld-cont-header">
                            <h2>Our Story</h2> 
                            <div className="ld-cont-h-linediv"></div>
                        </div> */}

                        <div>
                            <iframe src="https://player.vimeo.com/video/759163684?h=c6d4d2fd73&byline=0&portrait=0" 
                            // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                            allowfullscreen></iframe>
                        </div>
                    </section>

                    <section className="land-content-1" id="core-business">
                        <div className="ld-cont-header">
                            <h2>Core Business</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-1-container-1">
                            <div className="ld-cont-1-item">
                                <img src={corebusiness_1} alt="Mortgage Loan Refinance - QC Holiday" />
                                <h3>MORTGAGE LOAN REFINANCE</h3>
                                <div className="ld-cont-1-item-linediv" />
                                <p>Get your finances free with us! We are hands-on to provide a car mortgage loan refinance service in Malaysia with the lowest rate.</p>
                            </div>
                            <div className="ld-cont-1-item">
                                <img src={corebusiness_2} alt="Car Rental - QC Holiday" />
                                <h3>CAR RENTAL</h3>
                                <div className="ld-cont-1-item-linediv" />
                                <p>Explore the wide range of vehicle selections. Get the best prices for long-term car rental.</p>
                            </div>
                            <div className="ld-cont-1-item">
                                <img src={corebusiness_3} alt="Buy or Sell Your Car - QC Holiday" />
                                <h3>BUY OR SELL YOUR CAR</h3>
                                <div className="ld-cont-1-item-linediv" />
                                <p>Check out our free, secure methods for selling your car. We are committed to offering the GREAT rate in the market!</p>
                            </div>
                        </div>

                        <div className="ld-cont-1-container-2">
                            <div className="ld-cont-1-item-2 item-2-left item-2-mob-left">
                                <img src={corebusiness_4} alt="18+ Cities in Malaysia - QC Holiday" />
                                <p>18+ Cities <br/>
                                    <span>in Malaysia</span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                            <div className="ld-cont-1-item-2 item-2-left item-2-mob-right">
                                <img src={corebusiness_5} alt="1.5 Million Total Satisfied Customers - QC Holiday" />
                                <p>1.5 Million Total <br/>
                                    <span>satisfied customers</span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                            <div className="ld-cont-1-item-2 item-2-right item-2-mob-left">
                                <img src={corebusiness_6} alt="4,300+ Trusted Used Car Dealer - QC Holiday" />
                                <p>4,300+ Trusted <br/>
                                    <span>used car dealers</span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                            <div className="ld-cont-1-item-2 item-2-right item-2-mob-right">
                                <img src={corebusiness_7} alt="80,000 Transactions On An Annualized Basis - QC Holiday" />
                                <p>80,000 Transactions <br/>
                                    <span>on an annualized basis</span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                        </div>
                    </section>

                    
                    {/* <section className="land-content-2" id="about-us">
                        <div className="ld-cont-header">
                            <h2>Video</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                    </section> */}

                    
                    <section className="land-content-3" id="about-us">
                        <img src={aboutus_0} alt="Mortgage Loan Refinance - QC Holiday" className="ld-cont-3-img-mobile"/>
                        <div className="ld-cont-header">
                            <h2>About Us</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>
                        
                        <div className="ld-cont-3-container-1">
                            <div className="ld-cont-3-cont-1-item">
                                <div className="ld-cont-3-cont-1-item-1">
                                    <img src={aboutus_0} alt="Mortgage Loan Refinance - QC Holiday" />
                                </div>

                                <div className="ld-cont-3-cont-1-item-2">
                                    <p>We understand the challenges faced by many individuals while applying for bank loans for cars, QC HOLIDAY PREMIUM RENTAL SDN. BHD. strive to creatively provide comprehensive provision with regards to car rental, buying or selling a used car as far as to car mortgage financial service to ease everyone in debt repayment planning especially post MCO stage.</p>
                                    <p>We know that our small contribution to clients’ needs will create a huge impact on the economy; therefore, we support, empower, and provide high-touch service to every client by providing value-added services for Car Mortgage Loan refinancing. </p>
                                    <p>Ring us up for the first car mortgage refinance service, as long as you have a car certificate, no matter if you are blacklisted or bankrupt. We only deposit your car card and spare key, you will still get the ride.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="ld-cont-3-container-2">
                            <div className="ld-cont-3-cont-2-item">
                                <div className="ld-cont-3-cont-2-item-1">
                                    <h3>Vision</h3>
                                    <div className="ld-cont-3-cont-2-item-1-linediv"></div>
                                    <p>Our vision is to deliver the best car and financial solutions that fulfill today's needs and tomorrow's dreams.</p>
                                </div>

                                <div className="ld-cont-3-cont-2-item-1">
                                    <h3>Mission</h3>
                                    <div className="ld-cont-3-cont-2-item-1-linediv"></div>
                                    <p>Our mission is to help create one-stop solutions for people from transparent car inspection to ownership transfer as well as financing options.</p>
                                </div>
                            </div>
                        </div>

                    </section>

                    
                    <section className="land-content-4" id="why-choose-us">
                        <div className="ld-cont-header">
                            <h2>Why Choose Us</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-4-container-1">
                            <figure>
                                <img src={whychooseus_1} alt="Why Choose Us - QC Holiday" />
                            </figure>
                        </div>

                        <div className="ld-cont-4-container-2">
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>Zero Hassle</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>Expect a seamless journey from a free inspection to handling all the paperwork for you.</p>
                            </div>
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>Ultimate Peace Of Mind</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>No hidden fees, no mark-ups with extensive financing options</p>
                            </div>
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>Professional</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>Deliver the best customer service and consultancy through inspection to find out your car’s best value.</p>
                            </div>
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>Wide Range Of Vehicle Offer</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>Enjoy exceptional rebates, high trade-in values, and attractive ownership packages on a wide range of vehicles, including cars, motorcycle, lorry, or any vehicle types.</p>
                            </div>
                        </div>

                        <div className="ld-cont-4-container-3">
                            <div className="ld-cont-4-cont-3-item-2">
                                <figure onClick={handleSalesShow1}>
                                    <img src={sales_1} alt="David Heng - QC Holiday" />
                                </figure>
                            </div>

                            <div className="ld-cont-4-cont-3-item-2">
                                <figure onClick={handleSalesShow2}>
                                    <img src={sales_2} alt="Caslyn Koh - QC Holiday" />
                                </figure>
                            </div>

                            {/* <div className="ld-cont-4-cont-3-item-1">
                                <iframe src="https://player.vimeo.com/video/749881614?h=2e3f5db88a&title=0&byline=0&portrait=0" 
                                // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                                allowfullscreen></iframe>
                            </div>
                            
                            <div className="ld-cont-4-cont-3-item-1">
                                <iframe src="https://player.vimeo.com/video/754143660?h=c84a134a84&title=0&byline=0&portrait=0" 
                                // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                                allowfullscreen></iframe>
                            </div> */}
                        </div>
                    </section>
                    
                    
                    <section className="land-content-5" id="why-choose-us">
                        <div className="ld-cont-header">
                            <h2>Company Photo</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-5-container-1">
                            <figure>
                                <img src={companyphoto_1} alt="Our Company - QC Holiday" />
                            </figure>
                        </div>

                    </section>
                    
                    
                    <section className="land-content-6" id="customer-reviews">
                        <div className="ld-cont-header">
                            <h2>Customer Reviews</h2>
                            <div className="ld-cont-h-linediv"></div>                        
                        </div>
                        <p>Our clients range from students to high profile businessmen. We value each application!</p>

                        <div className="ld-cont-6-container-1">
                            <Slider {...reviews}>
                                <div className="ld-cont-6-item-1">
                                    <ReviewItem
                                        review_imgsrc={review_1}
                                        review_imgalttext="Customer Review - QC Holiday Johor"
                                        review_name="Jason Kok Chin Meng"
                                        review_location="Johor, Malaysia"
                                        review_rating={reviewrating5}
                                        review_title="SELL CAR WITH A 10% ABOVE MARKET VALUE"
                                        review_desc="Awesome car selling platform, the process is easy and smooth. Their advisor and staff were very kind. Overall, the service from the car inspection right up to delivery was quick and efficient."
                                    />
                                </div>

                                <div className="ld-cont-6-item-1">
                                    <ReviewItem
                                        review_imgsrc={review_2}
                                        review_imgalttext="Customer Review - QC Holiday Pahang"
                                        review_name="Nur Fazura"
                                        review_location="Pahang, Malaysia"
                                        review_rating={reviewrating5}
                                        review_title="CAR REFINANCE APPROVAL RM130K"
                                        review_desc="Worth dealing with them and will recommend people to rent a car here for sure. If you are looking for a hassle-free car refinance loan service in difficult times, this is a pleasant one… Highly recommended!"
                                    />
                                </div>

                                <div className="ld-cont-6-item-1">
                                    <ReviewItem
                                        review_imgsrc={review_3}
                                        review_imgalttext="Customer Review - QC Holiday Melaka"
                                        review_name="Joey Chang"
                                        review_location="Melaka, Malaysia"
                                        review_rating={reviewrating5}
                                        review_title="SELL CAR AT A GREAT RATE AND BUY A NEW RIDE"
                                        review_desc="As a woman I am not familiar with buying cars and inspection checking, however this company makes it transparent and is trustworthy. Thank you, QC HOLIDAY RENTAL, for the excellent price offer for selling my car, and the opportunity for me to get a better ride."
                                    />
                                </div>
                            </Slider>
                        </div>
                    </section>
                    
                    
                    <section className="land-content-7" id="contact-us">
                        <div className="ld-cont-header">
                            <h2>Contact Us Today</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>
                        <p>Ready to get a better car?</p>

                        <div className="ld-cont-7-container-1">
                            <div className="ld-cont-7-item-1">
                                <form className="cont-us-form-content"  ref={form} onSubmit={sendEmail}>
                                    <div className="cont-us-form-row-1">
                                        <div>
                                            <input type="text" id="fname" name="fname" placeholder="First Name" required/>
                                        </div>

                                        <div>
                                            <input type="text" id="lname" name="lname" placeholder="Last Name" required/>
                                        </div>
                                    </div>

                                    <div className="cont-us-form-row-1">
                                        <div>
                                            <input type="email" id="email" name="email" placeholder="Email" required/>
                                        </div>

                                        <div>
                                            <input type="tel" id="phonenumber" name="phonenumber" placeholder="Phone Number" />
                                        </div>
                                    </div>

                                    <div className="cont-us-form-row-2">
                                        <textarea type="text" id="message" name="message" placeholder="Message" required/>
                                    </div>
                                    
                                    <div className="cont-us-form-row-3">
                                        <button className="cont-us-form-submit" type="submit">Submit</button>
                                    </div>
                                </form> 
                            </div>
                            
                            <div className="ld-cont-7-item-2">
                                <figure>
                                    <img src={contactus_1} alt="Contact Us - QC Holiday" />
                                </figure>
                            </div>
                        </div>
                    </section>
                    
                    
                    <section className="land-content-8" id="range-of-cars">
                        <div className="ld-cont-header">
                            <h2>Our Range of Cars</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-8-container">
                            <div className="ld-cont-8-item hatchback">
                                <h3>Hatchback</h3>
                            </div>
                            <div className="ld-cont-8-item suv">
                                <h3>SUV</h3>
                            </div>
                            <div className="ld-cont-8-item mpv">
                                <h3>MPV</h3>
                            </div>
                            <div className="ld-cont-8-item sedan">
                                <h3>Sedan</h3>
                            </div>
                            <div className="ld-cont-8-item lorry">
                                <h3>Lorry</h3>
                            </div>
                            <div className="ld-cont-8-item others">
                                <h3>Others</h3>
                            </div>
                            
                        </div>
                    </section>
                </div>
            </div>



            {/* email alert */}
            <Modal show={show} onHide={handleClose} className="emailJS-modal" >
                <Modal.Header closeButton> </Modal.Header>

                <Modal.Body>
                    <img src={contactus_2} alt="Contact Us - QC Holiday"/>

                    <h3>Thank You</h3>
                    <p>You'll be hearing from us soon!</p>
                </Modal.Body>

                <Modal.Footer> </Modal.Footer>
            </Modal>



            {/* sales modal */}
            <Modal show={showsales1} onHide={handleSalesClose1} className="salesvid-modal" centered>
                <Modal.Body>
                    <iframe src="https://player.vimeo.com/video/749881614?h=2e3f5db88a&title=0&byline=0&portrait=0" 
                        // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                        allowfullscreen></iframe>
                </Modal.Body>
            </Modal>
            <Modal show={showsales2} onHide={handleSalesClose2} className="salesvid-modal" centered>
                <Modal.Body>
                    <iframe src="https://player.vimeo.com/video/754143660?h=c84a134a84&title=0&byline=0&portrait=0" 
                        // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                        allowfullscreen></iframe>
                </Modal.Body>
            </Modal>
        </>

    );

}

export default Landing;
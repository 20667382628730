import React from 'react';
import './Footer.scss';
import { GoChevronRight } from "react-icons/go";

function Footer() {


    return (

        <>
            <footer className="wrap-footer">
                <div className="footer-header">
                    <h2>Get in Touch</h2>
                    <div className="ld-cont-h-linediv"></div>
                </div>

                <div className="footer-container">
                    <div className="footer-container-1">
                        <img src="/QC LOGO-03.png" alt="QC Holiday" />
                        <p>QC HOLIDAY PREMIUM RENTAL SDN. BHD. strive to creatively provide comprehensive provision with regards to car rental, buying or selling a used car as far as to car mortgage financial service to ease everyone in debt repayment planning especially post MCO stage.</p>
                    </div>

                    <div className="footer-container-2">
                        <h3>Quick Link</h3>
                        <div>
                            <a href="/#core-business"><GoChevronRight /> Core Business</a>
                            <a href="/#about-us"><GoChevronRight /> About Us</a>
                            <a href="/#why-choose-us"><GoChevronRight /> Why Choose Us</a>
                            <a href="/#customer-reviews"><GoChevronRight /> Customer Reviews</a>
                            <a href="/#contact-us"><GoChevronRight /> Contact Us</a>
                            <a href="/#range-of-cars"><GoChevronRight /> Explore Range of Car</a>
                        </div>
                    </div>

                    <div className="footer-container-3">

                        <div className="f-cont-3-item-1">
                            Office Number: <a href="tel:+60342969397" target="_blank" rel="noreferrer nofollow">03-4296 9397</a> <br/>
                            Contact Number: <a href="tel:+60142533830" target="_blank" rel="noreferrer nofollow">014-253 3830</a> <br/>
                            Email: <a href="mailto:qcholidayrental@gmail.com" target="_blank" rel="noreferrer nofollow">qcholidayrental@gmail.com</a>
                        </div>

                        <div className="f-cont-3-item-2">
                            <address>
                                <b>Address:</b> <br/>
                                No. 16-gm, jalan pandan cahaya 1/1, taman pandan cahaya, 
                                68000 Ampang, selangor
                            </address>
                            <div>
                            <b>Operation Hours:</b><br/>
                                Monday to Friday 9:30a.m. – 6:00p.m. <br/>
                                Saturday 9:30a.m. – 3:00p.m.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-cr">
                    <p>© {new Date().getFullYear()} . QC Holiday . All Rights Reserved</p>
                </div>

            </footer>
        
        </>

    );

}

export default Footer;
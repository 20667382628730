import React from 'react';
import '../navigation/Footer.scss';
import { GoChevronRight } from "react-icons/go";

function Footer() {


    return (

        <>
            <footer className="wrap-footer">
                <div className="footer-header">
                    <h2>立即联系</h2>
                    <div className="ld-cont-h-linediv"></div>
                </div>

                <div className="footer-container">
                    <div className="footer-container-1">
                        <img src="/QC LOGO-03.png" alt="QC Holiday" />
                        <p>QC HOLIDAY PREMIUM RENTAL SDN. BHD。 
                            <br/>
                            努力创造性地提供有关汽车租赁，买卖二手车以及汽车抵押金融服务的全面服务，以减轻每个人的债务偿还计划，尤其是在MCO阶段之后。</p>
                    </div>

                    <div className="footer-container-2">
                        <h3>快速链接</h3>
                        <div>
                            <a href="#core-business"><GoChevronRight /> 主要业务</a>
                            <a href="#about-us"><GoChevronRight /> 关于我们</a>
                            <a href="#why-choose-us"><GoChevronRight /> 我们的优势</a>
                            <a href="#customer-reviews"><GoChevronRight /> 顾客评论</a>
                            <a href="#contact-us"><GoChevronRight /> 立即联系我们</a>
                            <a href="#range-of-cars"><GoChevronRight /> 探索我们的汽车系列</a>
                        </div>
                    </div>

                    <div className="footer-container-3">

                        <div className="f-cont-3-item-1">
                            公司电话 : <a href="tel:+60342969397" target="_blank" rel="noreferrer nofollow">03-4296 9397</a> <br/>
                            联系电话 : <a href="tel:+60142533830" target="_blank" rel="noreferrer nofollow">014-253 3830</a> <br/>
                            电子邮件 / E-mail : <a href="mailto:qcholidayrental@gmail.com" target="_blank" rel="noreferrer nofollow">qcholidayrental@gmail.com</a>
                        </div>

                        <div className="f-cont-3-item-2">
                            <address>
                                <b>地址 :</b> <br/>
                                No. 16-gm, jalan pandan cahaya 1/1, taman pandan cahaya, 
                                68000 Ampang, selangor
                            </address>
                            <div>
                            <b>营业时间 :</b><br/>
                                周一至周五 : 上午 9:30 时 – 下午 6:00 时 <br/>
                                星期六 : 上午 9:30 时 - 下午 3:00 时
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-cr">
                    <p>© {new Date().getFullYear()} . QC Holiday . All Rights Reserved</p>
                </div>

            </footer>
        
        </>

    );

}

export default Footer;
import React, { useRef, useState } from 'react';
import '../Landing.scss';
import Navbar from './NavbarBM';
import Footer from './FooterBM';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import emailjs from '@emailjs/browser';
import Modal from 'react-bootstrap/Modal';

function ReviewItem (props) {
    return (
        <>
            <div className="ld-review-item-header">
                <figure>
                    <img src={props.review_imgsrc} alt={props.review_imgalttext} />
                </figure>
                <div>
                    <div className="ld-review-name">{props.review_name}</div>
                    <div className="ld-review-location">{props.review_location}</div>
                    <div className="ld-review-rating">
                        {props.review_rating}
                        {/* <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span> */}
                    </div>
                </div>
            </div>
            <div className="ld-review-item-linediv"></div>
            <div className="ld-review-item-desc">
                <div>{props.review_title}</div>
                <p>{props.review_desc}</p>
            </div>
        </>
    )
}

function LandingBM() {

    const corebusiness_1 = require('../../images/BG_06.png');
    const corebusiness_2 = require('../../images/BG_03.png');
    const corebusiness_3 = require('../../images/BG_02.png');
    const corebusiness_4 = require('../../images/BG_08.png');
    const corebusiness_5 = require('../../images/BG_11.png');
    const corebusiness_6 = require('../../images/BG_09.png');
    const corebusiness_7 = require('../../images/BG_10.png');

    const aboutus_0 = require('../../images/BG_19_01.png');
    // const aboutus_1 = require('../../images/BG_19.png');

    const whychooseus_1 = require('../../images/BG_35.png');

    const sales_1 = require('../../images/sales_01.png');
    const sales_2 = require('../../images/sales_02.png');

    const companyphoto_1 = require('../../images/BG_36_01.jpg');

    const review_1 = require('../../images/review_01.png');
    const review_2 = require('../../images/review_02.png');
    const review_3 = require('../../images/review_03.png');

    const contactus_1 = require('../../images/BG_28_01.png');
    const contactus_2 = require('../../images/email_01.png');


    const reviews = {
        className: "center",
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        swipeToSlide: true,
        afterChange: function(index) {
            console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            );
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    };

    const reviewrating5 = [
        <>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        </>
    ]

    // modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
  
        //   emailjs.sendForm('SERVICE_ID', 'TEMPLATE_ID', form.current, 'USER_ID')
        emailjs.sendForm('service_95ty38a', 'template_ycj7qbb', form.current, 'FGCPYj26CkHeoYuvz')
            .then((result) => {
                console.log(result.text);
                // alert("表格发送成功！ 我们会尽快与您联系!");
                setShow(true);
            }, (error) => {
                console.log(error.text);
            });
    };
    
    // salesperson modal
    const [showsales1, setSalesShow1] = useState(false);
    const handleSalesClose1 = () => setSalesShow1(false);
    const handleSalesShow1 = () => setSalesShow1(true);
    
    const [showsales2, setSalesShow2] = useState(false);
    const handleSalesClose2 = () => setSalesShow2(false);
    const handleSalesShow2 = () => setSalesShow2(true);


    return (

        <>

            <Navbar />

            <div className="wrap-landing">

                <div className="wrap-hero-ld">
                    <div className="hero-ld-content">
                        <div className="hero-text-1">Membiayai Semula, Sewa, Beli, atau Jual kereta anda dengan kami!</div>

                        <h1>Malaysia No.1 Platform Pengedar Kereta Sehenti</h1>
                        <p>Pembekal kereta sehenti anda yang boleh dipercayai!</p>

                        <a href="#core-business" rel="noreferrer nofollow" className="hero-learnmore-btn">
                            <button type="button">Ketahui Lebih Lanjut</button>
                        </a>
                    </div>
                </div>


                <div className="land-container">

                    <section className="land-content-0">
                        {/* <div className="ld-cont-header">
                            <h2>Our Story</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div> */}
                        <div>
                            <iframe src="https://player.vimeo.com/video/759163684?h=c6d4d2fd73&byline=0&portrait=0" 
                            // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                            allowfullscreen></iframe>
                        </div>
                    </section>

                    <section className="land-content-1" id="perniagaan-teras">
                        <div className="ld-cont-header">
                            <h2>Perniagaan Teras</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-1-container-1">
                            <div className="ld-cont-1-item">
                                <img src={corebusiness_1} alt="Mortgage Loan Refinance - QC Holiday" />
                                <h3>PEMBIAYAAN SEMULA PINJAMAN GADAI</h3>
                                <div className="ld-cont-1-item-linediv" />
                                <p>Dapatkan kewangan anda secara percuma dengan kami! Kami adalah hands-on untuk menyediakan perkhidmatan pembiayaan semula pinjaman gadai janji kereta di Malaysia dengan kadar terendah.</p>
                            </div>
                            <div className="ld-cont-1-item">
                                <img src={corebusiness_2} alt="Car Rental - QC Holiday" />
                                <h3>SEWA KERETA</h3>
                                <div className="ld-cont-1-item-linediv" />
                                <p>Terokai pelbagai pilihan kenderaan. Dapatkan harga terbaik untuk sewa kereta jangka panjang.</p>
                            </div>
                            <div className="ld-cont-1-item">
                                <img src={corebusiness_3} alt="Buy or Sell Your Car - QC Holiday" />
                                <h3>BELI ATAU JUAL KERETA ANDA</h3>
                                <div className="ld-cont-1-item-linediv" />
                                <p>Lihat kaedah percuma dan selamat kami untuk menjual kereta anda. Kami komited untuk menawarkan kadar HEBAT di pasaran!</p>
                            </div>
                        </div>

                        <div className="ld-cont-1-container-2">
                            <div className="ld-cont-1-item-2 item-2-left item-2-mob-left">
                                <img src={corebusiness_4} alt="18+ Cities in Malaysia - QC Holiday" />
                                <p>18+ Bandar <br/>
                                    <span>di Malaysia</span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                            <div className="ld-cont-1-item-2 item-2-left item-2-mob-right">
                                <img src={corebusiness_5} alt="1.5 Million Total Satisfied Customers - QC Holiday" />
                                <p>1.5 Juta Jumlah <br/>
                                    <span>pelanggan yang berpuas hati</span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                            <div className="ld-cont-1-item-2 item-2-right item-2-mob-left">
                                <img src={corebusiness_6} alt="4,300+ Trusted Used Car Dealer - QC Holiday" />
                                <p>4,300+ <br/>
                                    <span>pengedar kereta terpakai yang dipercayai</span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                            <div className="ld-cont-1-item-2 item-2-right item-2-mob-right">
                                <img src={corebusiness_7} alt="80,000 Transactions On An Annualized Basis - QC Holiday" />
                                <p>80,000 Transaksi<br/>
                                    <span>pada asas tahunan</span>
                                </p>
                                <div className="ld-cont-1-item-linediv" />
                            </div>
                        </div>
                    </section>

                    
                    {/* <section className="land-content-2" id="about-us">
                        <div className="ld-cont-header">
                            <h2>Video</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                    </section> */}

                    
                    <section className="land-content-3" id="tentang-kita">
                        <img src={aboutus_0} alt="Mortgage Loan Refinance - QC Holiday" className="ld-cont-3-img-mobile"/>
                        <div className="ld-cont-header">
                            <h2>Tentang Kita</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>
                        
                        <div className="ld-cont-3-container-1">
                            <div className="ld-cont-3-cont-1-item">
                                <div className="ld-cont-3-cont-1-item-1">
                                    <img src={aboutus_0} alt="Mortgage Loan Refinance - QC Holiday" />
                                </div>

                                <div className="ld-cont-3-cont-1-item-2">
                                    <p>Kami memahami cabaran yang dihadapi oleh ramai individu semasa memohon pinjaman bank untuk kereta, QC HOLIDAY PREMIUM RENTAL SDN. BHD. berusaha untuk secara kreatif menyediakan peruntukan yang komprehensif berkaitan dengan penyewaan kereta, membeli atau menjual kereta terpakai setakat perkhidmatan kewangan gadai janji kereta untuk memudahkan semua orang dalam perancangan pembayaran balik terutamanya semasa PKP.</p>
                                    <p>Kami tahu bahawa sumbangan kecil kami kepada keperluan pelanggan akan mewujudkan impak yang besar kepada ekonomi; oleh itu, kami menyokong, memperkasa dan menyediakan perkhidmatan sentuhan tinggi kepada setiap pelanggan dengan menyediakan perkhidmatan nilai tambah untuk pembiayaan semula Pinjaman Gadai Janji Kereta.</p>
                                    <p>Hubungi kami untuk perkhidmatan pembiayaan semula gadai janji kereta yang pertama, selagi anda mempunyai sijil kereta, tidak kira jika anda disenaraihitamkan atau muflis. Kami hanya mendepositkan kad kereta dan kunci ganti anda, anda masih akan mendapat perjalanan.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="ld-cont-3-container-2">
                            <div className="ld-cont-3-cont-2-item">
                                <div className="ld-cont-3-cont-2-item-1">
                                    <h3>Visi</h3>
                                    <div className="ld-cont-3-cont-2-item-1-linediv"></div>
                                    <p>Visi kami adalah untuk menyampaikan penyelesaian kereta dan kewangan terbaik yang memenuhi keperluan hari ini dan impian hari esok.</p>
                                </div>

                                <div className="ld-cont-3-cont-2-item-1">
                                    <h3>Misi</h3>
                                    <div className="ld-cont-3-cont-2-item-1-linediv"></div>
                                    <p>Misi kami adalah untuk membantu mencipta penyelesaian sehenti untuk orang ramai daripada pemeriksaan kereta yang telus kepada pemindahan pemilikan serta pilihan pembiayaan.</p>
                                </div>
                            </div>
                        </div>

                    </section>

                    
                    <section className="land-content-4" id="kenapa-pilih-kami">
                        <div className="ld-cont-header">
                            <h2>Kenapa Pilih Kami</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-4-container-1">
                            <figure>
                                <img src={whychooseus_1} alt="Why Choose Us - QC Holiday" />
                            </figure>
                        </div>

                        <div className="ld-cont-4-container-2">
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>Proses Mudah</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>Daripada pemeriksaan kereta percuma hingga mengendalikan semua kertas kerja untuk anda, prosesnya diperkemas.</p>
                            </div>
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>Selamat Dan Bebas Kebimbangan</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>Tiada yuran tersembunyi, tiada mark-up dengan pilihan pembiayaan yang luas.</p>
                            </div>
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>Profesional</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>Berikan perkhidmatan pelanggan dan perundingan terbaik melalui pemeriksaan untuk mengetahui nilai terbaik kereta anda.</p>
                            </div>
                            <div className="ld-cont-4-cont-2-item-1">
                                <h3>Pelbagai Tawaran Kenderaan</h3>
                                <div className="ld-cont-4-linediv"></div>
                                <p>Nikmati rebat yang luar biasa, nilai tukar beli yang tinggi dan pakej pemilikan yang menarik pada pelbagai jenis kenderaan, termasuk kereta, motosikal, lori atau sebarang jenis kenderaan.</p>
                            </div>
                        </div>

                        <div className="ld-cont-4-container-3">
                            <div className="ld-cont-4-cont-3-item-2">
                                <figure onClick={handleSalesShow1}>
                                    <img src={sales_1} alt="David Heng - QC Holiday" />
                                </figure>
                            </div>

                            <div className="ld-cont-4-cont-3-item-2">
                                <figure onClick={handleSalesShow2}>
                                    <img src={sales_2} alt="Caslyn Koh - QC Holiday" />
                                </figure>
                            </div>

                            {/* <div className="ld-cont-4-cont-3-item-1">
                                <iframe src="https://player.vimeo.com/video/749881614?h=2e3f5db88a&title=0&byline=0&portrait=0" 
                                // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                                allowfullscreen></iframe>
                            </div>
                            
                            <div className="ld-cont-4-cont-3-item-1">
                                <iframe src="https://player.vimeo.com/video/754143660?h=c84a134a84&title=0&byline=0&portrait=0" 
                                // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                                allowfullscreen></iframe>
                            </div> */}
                        </div>
                    </section>
                    
                    
                    <section className="land-content-5" id="kenapa-pilih-kami">
                        <div className="ld-cont-header">
                            <h2>Syarikat Kami</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-5-container-1">
                            <figure>
                                <img src={companyphoto_1} alt="Our Company - QC Holiday" />
                            </figure>
                        </div>

                    </section>
                    
                    
                    <section className="land-content-6" id="maklumbalas-pelanggan">
                        <div className="ld-cont-header">
                            <h2>Maklumbalas Pelanggan</h2>
                            <div className="ld-cont-h-linediv"></div>                        
                        </div>
                        <p>Pelanggan kami terdiri daripada pelajar hingga ahli perniagaan berprofil tinggi. Kami menghargai setiap permohonan!</p>

                        <div className="ld-cont-6-container-1">
                            <Slider {...reviews}>
                                <div className="ld-cont-6-item-1">
                                    <ReviewItem
                                        review_imgsrc={review_1}
                                        review_imgalttext="Customer Review - QC Holiday Johor"
                                        review_name="Jason Kok Chin Meng"
                                        review_location="Johor, Malaysia"
                                        review_rating={reviewrating5}
                                        review_title="JUAL KERETA DENGAN 10% NILAI PASARAN"
                                        review_desc="Platform jualan kereta yang hebat, prosesnya lancar dan mudah. Perunding dan kakitangan mereka sangat mesra. Secara keseluruhan perkhidmatan dari pemeriksaan kereta hingga penghantaran adalah pantas dan cekap."
                                    />
                                </div>

                                <div className="ld-cont-6-item-1">
                                    <ReviewItem
                                        review_imgsrc={review_2}
                                        review_imgalttext="Customer Review - QC Holiday Pahang"
                                        review_name="Nur Fazura"
                                        review_location="Pahang, Malaysia"
                                        review_rating={reviewrating5}
                                        review_title="Pembiayaan semula kereta diluluskan RM130K"
                                        review_desc="Menghubungi mereka adalah pilihan terbaik. Jika anda berada dalam kecemasan atau masa cubaan dan sedang mencari pinjaman pembiayaan semula kereta, syarikat ini adalah pilihan yang bagus...sangat disyorkan!"
                                    />
                                </div>

                                <div className="ld-cont-6-item-1">
                                    <ReviewItem
                                        review_imgsrc={review_3}
                                        review_imgalttext="Customer Review - QC Holiday Melaka"
                                        review_name="Joey Chang"
                                        review_location="Melaka, Malaysia"
                                        review_rating={reviewrating5}
                                        review_title="Jual kereta pada harga yang tinggi dan beli yang baru"
                                        review_desc="Sebagai seorang wanita, saya tidak biasa membeli dan memeriksa kereta, tetapi syarikat ini menjadikannya telus dan boleh dipercayai. Terima kasih QC HOLIDAY RENTAL kerana menjual kereta saya pada harga yang hebat dan memberi saya peluang untuk mendapatkan harga jualan yang lebih tinggi."
                                    />
                                </div>
                            </Slider>
                        </div>
                    </section>
                    
                    
                    <section className="land-content-7" id="hubungi-kami">
                        <div className="ld-cont-header">
                            <h2>立即联系我们</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>
                        <p>准备好换一辆更好的车了吗？</p>

                        <div className="ld-cont-7-container-1">
                            <div className="ld-cont-7-item-1">
                                <form className="cont-us-form-content" ref={form} onSubmit={sendEmail} >
                                    <div className="cont-us-form-row-1">
                                        <div>
                                            <input type="text" id="fname" name="fname" placeholder="名字 / First Name" required/>
                                        </div>
                                        
                                        <div>
                                            <input type="text" id="lname" name="lname" placeholder="姓名 / Last Name" required/>
                                        </div>
                                    </div>

                                    <div className="cont-us-form-row-1">
                                        <div>
                                            <input type="email" id="email" name="email" placeholder="电子邮件 / Email Address" required/>
                                        </div>

                                        <div>
                                            <input type="tel" id="phonenumber" name="phonenumber" placeholder="联系电话 / Phone Number" />
                                        </div>
                                    </div>

                                    <div className="cont-us-form-row-2">
                                        <textarea type="text" id="message" name="message" placeholder="留言 / Message" required/>
                                    </div>
                                    
                                    <div className="cont-us-form-row-3">
                                        <button className="cont-us-form-submit" type="submit">Submit</button>
                                    </div>
                                </form> 
                            </div>
                            
                            <div className="ld-cont-7-item-2">
                                <figure>
                                    <img src={contactus_1} alt="Contact Us - QC Holiday" />
                                </figure>
                            </div>
                        </div>
                    </section>
                    
                    
                    <section className="land-content-8" id="jenis-kereta">
                        <div className="ld-cont-header">
                            <h2>Jenis Kereta</h2>
                            <div className="ld-cont-h-linediv"></div>
                        </div>

                        <div className="ld-cont-8-container">
                            <div className="ld-cont-8-item hatchback">
                                <h3>Hatchback</h3>
                            </div>
                            <div className="ld-cont-8-item suv">
                                <h3>SUV</h3>
                            </div>
                            <div className="ld-cont-8-item mpv">
                                <h3>MPV</h3>
                            </div>
                            <div className="ld-cont-8-item sedan">
                                <h3>Sedan</h3>
                            </div>
                            <div className="ld-cont-8-item lorry">
                                <h3>Lori</h3>
                            </div>
                            <div className="ld-cont-8-item others">
                                <h3>Lain-lain</h3>
                            </div>
                            
                        </div>
                    </section>
                </div>
            </div>
        
            <Modal show={show} onHide={handleClose} className="emailJS-modal">
                <Modal.Header closeButton> </Modal.Header>

                <Modal.Body>
                    <img src={contactus_2} alt="Contact Us - QC Holiday"/>

                    <h3>谢谢</h3>
                    <p>您很快就会收到我们的来信!</p>
                </Modal.Body>

                <Modal.Footer> </Modal.Footer>
            </Modal>

            {/* sales modal */}
            <Modal show={showsales1} onHide={handleSalesClose1} className="salesvid-modal" centered>
                <Modal.Body>
                    <iframe src="https://player.vimeo.com/video/749881614?h=2e3f5db88a&title=0&byline=0&portrait=0" 
                        // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                        allowfullscreen></iframe>
                </Modal.Body>
            </Modal>
            <Modal show={showsales2} onHide={handleSalesClose2} className="salesvid-modal" centered>
                <Modal.Body>
                    <iframe src="https://player.vimeo.com/video/754143660?h=c84a134a84&title=0&byline=0&portrait=0" 
                        // style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                        allowfullscreen></iframe>
                </Modal.Body>
            </Modal>

            <Footer />
        </>

    );

}

export default LandingBM;

import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';    /* Switch = Routes in react-router-dom v6 */

import Home from './components/pages/Landing_Page';
import LandingZH from './components/ZH/LandingZH';
import LandingBM from './components/BM/LandingBM';
import NotFoundPage from './components/pages/NotFound_Page';


function App() {
    return (
        <>
            <Router>

                <Routes>
                    <Route path="/" element={<Home />} />

                    <Route path="/zh" element={<LandingZH />} />

                    <Route path="/bm" element={<LandingBM />} />
                    
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>

            
            </Router>
        
        </>
    );
}

export default App;

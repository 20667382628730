import React from 'react';
import '../navigation/Footer.scss';
import { GoChevronRight } from "react-icons/go";

function Footer() {


    return (

        <>
            <footer className="wrap-footer">
                <div className="footer-header">
                    <h2>Hubungi</h2>
                    <div className="ld-cont-h-linediv"></div>
                </div>

                <div className="footer-container">
                    <div className="footer-container-1">
                        <img src="/QC LOGO-03.png" alt="QC Holiday" />
                        <p>QC HOLIDAY PREMIUM RENTAL SDN. BHD。 
                            <br/>
                            berusaha untuk secara kreatif menyediakan peruntukan yang komprehensif berkaitan dengan penyewaan kereta, membeli atau menjual kereta terpakai setakat perkhidmatan kewangan gadai janji kereta untuk memudahkan semua orang dalam perancangan pembayaran balik terutamanya peringkat pasca PKP.</p>
                    </div>

                    <div className="footer-container-2">
                        <h3>Pautan Pantas</h3>
                        <div>
                            <a href="#perniagaan-teras"><GoChevronRight />Perniagaan Teras</a>
                            <a href="#tentang-kita"><GoChevronRight /> Tentang Kita </a>
                            <a href="#kenapa-pilih-kami"><GoChevronRight /> Kenapa Pilih Kami</a>
                            <a href="#maklumbalas-pelanggan"><GoChevronRight /> Maklumbalas Pelanggan</a>
                            <a href="#hubungi-kami"><GoChevronRight /> Hubungi Kami</a>
                            <a href="#jenis-kereta"><GoChevronRight /> Jenis Kereta</a>
                        </div>
                    </div>

                    <div className="footer-container-3">

                        <div className="f-cont-3-item-1">
                        Nombor Pejabat : <a href="tel:+60342969397" target="_blank" rel="noreferrer nofollow">03-4296 9397</a> <br/>
                        Nombor Perhubungan : <a href="tel:+60142533830" target="_blank" rel="noreferrer nofollow">014-253 3830</a> <br/>
                        E-mel : <a href="mailto:qcholidayrental@gmail.com" target="_blank" rel="noreferrer nofollow">qcholidayrental@gmail.com</a>
                        </div>

                        <div className="f-cont-3-item-2">
                            <address>
                                <b>Alamat :</b> <br/>
                                No. 16-gm, jalan pandan cahaya 1/1, taman pandan cahaya, 
                                68000 Ampang, selangor
                            </address>
                            <div>
                            <b>Waktu Operasi :</b><br/>
                            Isnin hingga Jumaat 9:30a.m. - 6:00p.m. <br/>
                            Sabtu 9:30a.m. - 3:00 petang.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-cr">
                    <p>© {new Date().getFullYear()} . QC Holiday . All Rights Reserved</p>
                </div>

            </footer>
        
        </>

    );

}

export default Footer;
